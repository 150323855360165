<template>
  <div class="device-item">
    <el-tabs class="tabs" v-model="fullPath" @tab-click="onLink">
      <el-tab-pane v-for="item in deviceMenuObj" :key="item.path" :label="item.title" :name="item.path">
      </el-tab-pane>
    </el-tabs>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deviceMenuObj: [
        {
          title: "售卖柜订单",
          path: "/mall/mallOrder/cabinetOrderList"
        },
        {
          title: "派送订单",
          path: "/mall/mallOrder/deliveryOrderList"
        },
      ],
      fullPath: this.$route.path
    };
  },
  methods: {
    onLink() {
      if (this.$route.path != this.fullPath) {
        this.$router.push({
          path: this.fullPath,
          query: this.$route.query
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs {
  padding: 0 .15rem;
}
</style>